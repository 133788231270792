import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SvgUploadFile from "@/assets/icons/SVGUploadFile.tsx";
import Modal from "@/components/Modal";
import UploadFileStep from "@/components/UploadFilesModal/UploadFileStep.tsx";
import FileInfoStep from "@/components/UploadFilesModal/FileInfoStep.tsx";
import FileComponent from "@/components/FileComponent";
import SVGLock from "@/assets/icons/SVGLock.tsx";
import FormAutocompleteTags from "@/components/FormAutocompleteTags";
import SVGUploadFile from "@/assets/icons/SVGUploadFile.tsx";
import { StyledIconButton, StyledRightActionButton } from "@/components/styled";
import FormSelect from "@/components/FormSelect";
import FormInput from "@/components/FormInput";
import FileTypeForm from "@/components/UploadProjectFile/FileTypeForm.tsx";
import ProjectSelect from "@/components/UploadProjectFile/ProjectSelect.tsx";
import { ProjectRecord } from "@/types";
import SVGUserEdit from "@/assets/icons/SVGUserEdit.tsx";
import { updateTemplateReq } from "@/api/templateApi";
import { FILE_TYPES } from "@/constants";

export type UploadFileForm = {
  file: File;
  type: string;
  otherFileType?: string;
  serialNumber?: string;
  quarterlyPerformanceIncentiveValue?: string;
};

const UploadProjectFile = ({
  uploadFile,
  isButton = false,
  showProjectSelect = false,
}: {
  uploadFile: (data: FormData, id?: ProjectRecord["id"]) => void;
  isButton?: boolean;
  showProjectSelect?: boolean;
}) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);

  const {
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: { isValid },
  } = useForm<UploadFileForm>({
    mode: "onChange",
  });
  const file = watch("file");
  const fileType = watch("type");
  const otherFileType = watch("otherFileType");
  const quarterlyPerformanceIncentiveValue = watch(
    "quarterlyPerformanceIncentiveValue",
  );

  const handleClose = () => {
    setOpen(false);
    reset();
    setStep(1);
  };

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData();
      formData.append("file", data.file);

      switch (data.type) {
        case FILE_TYPES.SERIAL_NUMBER: {
          formData.append("type", data.type);
          formData.append("serialNumber", data.serialNumber);
          break;
        }
        case FILE_TYPES.PERFORMANCE_REPORT: {
          formData.append("type", data.type);
          formData.append(
            "quarterlyPerformanceIncentiveValue",
            data.quarterlyPerformanceIncentiveValue,
          );
          break;
        }
        case FILE_TYPES.OTHER: {
          formData.append("type", data.otherFileType!);
          break;
        }
        default: {
          formData.append("type", data.type);
        }
      }

      if (data?.project?.id) {
        uploadFile(formData, data?.project?.id);
      } else {
        await uploadFile(formData);
      }
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  const isLastStep = step === 2;
  const isNextStepDisabled = () => {
    if (step === 1 && !file) return true;
    if (step === 2) {
      switch (fileType) {
        case FILE_TYPES.PERFORMANCE_REPORT:
          return !quarterlyPerformanceIncentiveValue;
        case FILE_TYPES.OTHER:
          return !otherFileType;
        default:
          return !fileType;
      }
    }
    return false;
  };
  return (
    <>
      {isButton && (
        <Box
          sx={{
            ...(!downSm && { maxWidth: "180px", width: "100%" }),
          }}
        >
          <StyledRightActionButton
            onClick={() => setOpen(true)}
            sx={{ width: "100%", gap: "6px", whiteSpace: "nowrap" }}
            variant={"contained"}
          >
            <SVGUploadFile />
            {!downSm && "Upload file"}
          </StyledRightActionButton>
        </Box>
      )}
      {!isButton && (
        <StyledIconButton
          sx={{ flex: 1 }}
          variant={downSm ? "outlined" : "text"}
          onClick={() => setOpen(true)}
        >
          {downSm ? "Upload file" : <SVGUploadFile />}
        </StyledIconButton>
      )}
      <Modal open={open} onClose={handleClose} title={"Upload file"}>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            marginTop: "24px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          {step === 1 && <UploadFileStep file={file} control={control} />}
          {step === 2 && (
            <FileTypeForm
              fileInfo={file}
              control={control}
              fileType={fileType}
              showProjectSelect={showProjectSelect}
            />
          )}
          <Box
            sx={{
              marginTop: "32px",
              display: "flex",
              gap: "8px",
              width: "100%",
            }}
          >
            <Button
              variant={"outlined"}
              sx={{ width: "100%" }}
              onClick={() => (step === 1 ? handleClose() : prevStep())}
            >
              Back
            </Button>
            {isLastStep && (
              <Button
                variant={"contained"}
                sx={{ width: "100%" }}
                type={"submit"}
                disabled={!isValid || isNextStepDisabled()}
              >
                Upload file
              </Button>
            )}
            {!isLastStep && (
              <Button
                variant={"contained"}
                sx={{ width: "100%" }}
                onClick={nextStep}
                disabled={!isValid || isNextStepDisabled()}
              >
                Continue
              </Button>
            )}
          </Box>
        </form>
      </Modal>
    </>
  );
};

export default UploadProjectFile;
